@font-face {
  font-family: 'Open Sans Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Open Sans Regular'), url(./assets/fonts/OpenSans-Regular.woff2) format('woff2'),
    url(./assets/fonts/OpenSans-Regular.woff) format('woff');
}
@font-face {
  font-family: 'Open Sans SemiBold';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans SemiBold'), url(./assets/fonts/OpenSans-SemiBold.woff2) format('woff2'),
    url(./assets/fonts/OpenSans-SemiBold.woff) format('woff');
}
@font-face {
  font-family: 'Open Sans Bold';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), url(./assets/fonts/OpenSans-Bold.woff2) format('woff2'),
    url(./assets/fonts/OpenSans-Bold.woff) format('woff');
}
